.container {
    max-width: 1400px;
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0 20px;
    @include media("<=laptop") {
        display: block;
        padding: 0;
    }

    >* {
        flex: auto
    }
}



//content
.site-content {
    color: #FFF;
    padding-top: 50px;
    @include media("<=laptop") {
        padding-top: 0px;
    }
}

.cell {
    margin: 0 auto;
    padding: 20px 40px;
    @include media("<=laptop") {
        padding: 20px;
    }
}


//left col
.profile {
    margin-top: 20vh;
    width: 100%;
    display: block;
    @include media("<=laptop") {
        margin: 30px auto;
        width: 90%;
        max-width: 400px;
    }
}

.content {
    margin-top: 25vh;
    max-width: 650px;
    @include media("<=laptop") {
        margin: auto;
        max-width: 100%;
    }
}

//right col
.main-info {
    display: flex;
    flex-wrap: wrap;

    @include media("<=laptop") {
        .content {
            order: 1;
        }
        .site-info {
            order: 2;
        }
    }
}
.site-info {
    display: flex;

    @include media("<=laptop") {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 30px auto;
    }

    > a {
        margin-right:1.3em;
        transition: .3s ease all;
        @include media("<=laptop") {
            margin: .5em auto;
        }
    }
}
.socials {
    display: inline-flex;
    align-self: center;
    margin-left: 1em;
    @include media("<=laptop") {
        margin: 2em auto;
    }
    a {
        margin-right:1em;
    }
}

.icon {
    height: 28px;
    width: auto;
    opacity: 1;
    transition: .3s ease all;

    @include media("<=laptop") {
        height: 35px;
    }
    &:hover {
        opacity: .6;
        transition: .3s ease all;
    }
}

.links {
    display: flex;
    // flex-wrap: wrap;
    @include media("<=laptop") {
        display: block;
        margin-top: 3em;
    }

    a {
        font-size: 90%;
        margin-right: 1em;
        color: $brand-secondary;
    }

    small {
        color: $brand-secondary;
    }

    .link {
        display: block;
        text-align: center;
        @include media("<=laptop") {
            a {
                width: 100%;
            }
        }
    }
}

small {
    font-size: 70%;
}