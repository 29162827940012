html {
	box-sizing: border-box;
    // font-size:62.5%;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	min-height: 100vh;
	background-color: #01261C; 
	background-image: url('/dist/images/3neon.svg');
	background-repeat: no-repeat;
	background-size: 70vh auto;
	background-position: -5% 150%;
	@include media("<=laptop") {
    background-image: none;
	}
}

blockquote, q {
	quotes: "" "";
    margin: 20px 0 20px 50px;
    border-left: 10px solid $brand-lightgrey;
    padding: 0 50px;
	&:before,
	&:after {
		content: "";
	}
}

hr {
	background-color: $brand-darkgrey;
	border: 0;
	height: 1px;
	margin: 30px 0;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

@import "lists";
//@include scrollbars(.3em, $brand-primary, #FFF);

//gmaps
//.acf-map {
//	width: 100%;
//	height: 400px;
//	border: 1px solid $brand-grey;
//	margin: 20px 0;
//}
//
///* fixes potential theme css conflict */
//.acf-map img {
//   max-width: inherit !important;
//}

@import "reusable";
@import "tables";


