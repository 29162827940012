h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0 0 .8em 0;
}

h1 {
    @include fluid($h1);
    color: #FFF;
    text-transform: uppercase;
    font-family: $brand-heading;
    letter-spacing: .03em;
    margin: 0 0 .3em;
}