//colours
//common
$brand-lightgrey: #E0E0E0;
$brand-darkgrey: #121212;

//brand
$brand-primary: #00FFBB;
$brand-secondary: #FF595A;
$brand-placeholder-color:#00FFBB;

//fonts
$brand-font: "merriweather", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$brand-heading: "brandon-grotesque", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$brand-code: Menlo, Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;

$fluid-base: (
    min-vw: 490px,
    max-vw: 3000px,
    min-fs: 16px,
    max-fs: 20px,
    line-height: 1.8
);

$code: (
    min-vw: 490px,
    max-vw: 3000px,
    min-fs: 14px,
    max-fs: 18px,
    line-height: 1.4
);

$h1: (
    min-vw: 490px,
    max-vw: 3000px,
    min-fs:22px,
    max-fs: 36px,
    line-height: 1.2
);

$small: (
    min-vw: 490px,
    max-vw: 3000px,
    min-fs:14px,
    max-fs: 18px,
    line-height: 1.2
);