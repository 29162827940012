//the usual bg suspects
%background-styles {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


// font utilities
// Alignment
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-break {
    word-break: break-all;
}

// Transformation
.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}