// Unordered and Ordered lists
ul,
ol {
    margin-top: 0;
    margin-bottom: 1.5em;
    ul,
    ol {
        margin-bottom: 0;
    }
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li > ul,
li > ol {
    margin-bottom: 0;
    margin-left: 0;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0 1.5em 1.5em;
}