/**
* Remove unit from a number so we can math.
*/
/**
  * We use a percentage value when instead of a rem value on the fluid font size
  * so that it plays nice with IE and Safari.
  */
/**
  * Convert line height to percentage for cross browser compatibility.
  */
/**
  * Helper function to get a key value from the base options map.
  */
/**
  * Helper function to convert px to rem for the baseline. This will help keep things fluid.
  */
/**
  * Optional mixin to help create a nice vertical rythm. Search "vertical ryhtm golden ratio".
  */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, .btn,
.button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button, .btn,
.button {
  overflow: visible;
}

button, .btn,
.button,
select {
  text-transform: none;
}

button, .btn,
.button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], .btn[disabled],
.button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, .btn::-moz-focus-inner,
.button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

body, button, .btn,
.button,
input,
select,
textarea {
  color: #00FFBB;
  font-family: "merriweather", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 180%;
}

@media screen and (min-width: 30.625rem) {
  body, button, .btn,
  .button,
  input,
  select,
  textarea {
    font-size: calc(100% + 0.25 * ((100vw - 3062.5%) / 156.875));
  }
}

@media screen and (min-width: 187.5rem) {
  body, button, .btn,
  .button,
  input,
  select,
  textarea {
    font-size: 1.25rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0 0 .8em 0;
}

h1 {
  font-size: 22px;
  line-height: 120%;
  color: #FFF;
  text-transform: uppercase;
  font-family: "brandon-grotesque", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: .03em;
  margin: 0 0 .3em;
}

@media screen and (min-width: 30.625rem) {
  h1 {
    font-size: calc(137.5% + 0.875 * ((100vw - 3062.5%) / 156.875));
  }
}

@media screen and (min-width: 187.5rem) {
  h1 {
    font-size: 2.25rem;
  }
}

p {
  margin-bottom: 1.5em;
  margin-top: 0;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #E0E0E0;
  font-family: Menlo, Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

@media screen and (min-width: 30.625rem) {
  pre {
    font-size: calc(87.5% + 0.25 * ((100vw - 3062.5%) / 156.875));
  }
}

@media screen and (min-width: 187.5rem) {
  pre {
    font-size: 1.125rem;
  }
}

code,
kbd,
tt,
var {
  font-family: Menlo, Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 14px;
  line-height: 140%;
}

@media screen and (min-width: 30.625rem) {
  code,
  kbd,
  tt,
  var {
    font-size: calc(87.5% + 0.25 * ((100vw - 3062.5%) / 156.875));
  }
}

@media screen and (min-width: 187.5rem) {
  code,
  kbd,
  tt,
  var {
    font-size: 1.125rem;
  }
}

abbr,
acronym {
  border-bottom: 1px dotted #00FFBB;
  cursor: help;
}

mark,
ins {
  background: #E0E0E0;
  text-decoration: none;
  padding: 0 .2em;
}

big {
  font-size: 125%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  background-color: #01261C;
  background-image: url("/dist/images/3neon.svg");
  background-repeat: no-repeat;
  background-size: 70vh auto;
  background-position: -5% 150%;
}

@media (max-width: 1024px) {
  body {
    background-image: none;
  }
}

blockquote, q {
  quotes: "" "";
  margin: 20px 0 20px 50px;
  border-left: 10px solid #E0E0E0;
  padding: 0 50px;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
}

hr {
  background-color: #121212;
  border: 0;
  height: 1px;
  margin: 30px 0;
}

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 1.5em;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-break {
  word-break: break-all;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

table {
  margin: 1em 0;
  width: 100%;
  border: 1px solid #E0E0E0;
  border-collapse: collapse;
  text-align: left;
}

table td {
  padding: .2em;
  border: 1px solid #E0E0E0;
}

button, .btn,
.button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: none;
  border: none;
  padding: .8em 3em;
  transition: .3s ease all;
  background-color: #FF595A;
  color: #FFF;
  border-radius: 10px;
  display: inline-block;
  line-height: 1.3;
}

button:visited, .btn:visited,
.button:visited,
input[type="button"]:visited,
input[type="reset"]:visited,
input[type="submit"]:visited {
  color: #FFF;
}

button:hover, .btn:hover,
.button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  background-color: #00FFBB;
  color: #FFF;
  transition: .3s ease all;
  cursor: pointer;
}

.btn-clear,
.button-clear {
  text-decoration: underline;
  padding: .8em 3em;
  transition: .3s ease all;
  display: block;
  line-height: 1.3;
}

.btn-clear:hover,
.button-clear:hover {
  color: #00FFBB;
}

/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  color: #00FFBB;
  text-decoration: none;
}

a:visited {
  color: #00FFBB;
}

a:hover, a:active {
  color: #FF595A;
  text-decoration: none;
}

a:focus {
  outline: 2px dotted #00FFBB;
  text-decoration: none;
}

a:hover, a:active {
  outline: 0;
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.container {
  max-width: 1400px;
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 1024px) {
  .container {
    display: block;
    padding: 0;
  }
}

.container > * {
  flex: auto;
}

.site-content {
  color: #FFF;
  padding-top: 50px;
}

@media (max-width: 1024px) {
  .site-content {
    padding-top: 0px;
  }
}

.cell {
  margin: 0 auto;
  padding: 20px 40px;
}

@media (max-width: 1024px) {
  .cell {
    padding: 20px;
  }
}

.profile {
  margin-top: 20vh;
  width: 100%;
  display: block;
}

@media (max-width: 1024px) {
  .profile {
    margin: 30px auto;
    width: 90%;
    max-width: 400px;
  }
}

.content {
  margin-top: 25vh;
  max-width: 650px;
}

@media (max-width: 1024px) {
  .content {
    margin: auto;
    max-width: 100%;
  }
}

.main-info {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .main-info .content {
    order: 1;
  }
  .main-info .site-info {
    order: 2;
  }
}

.site-info {
  display: flex;
}

@media (max-width: 1024px) {
  .site-info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
  }
}

.site-info > a {
  margin-right: 1.3em;
  transition: .3s ease all;
}

@media (max-width: 1024px) {
  .site-info > a {
    margin: .5em auto;
  }
}

.socials {
  display: inline-flex;
  align-self: center;
  margin-left: 1em;
}

@media (max-width: 1024px) {
  .socials {
    margin: 2em auto;
  }
}

.socials a {
  margin-right: 1em;
}

.icon {
  height: 28px;
  width: auto;
  opacity: 1;
  transition: .3s ease all;
}

@media (max-width: 1024px) {
  .icon {
    height: 35px;
  }
}

.icon:hover {
  opacity: .6;
  transition: .3s ease all;
}

.links {
  display: flex;
}

@media (max-width: 1024px) {
  .links {
    display: block;
    margin-top: 3em;
  }
}

.links a {
  font-size: 90%;
  margin-right: 1em;
  color: #FF595A;
}

.links small {
  color: #FF595A;
}

.links .link {
  display: block;
  text-align: center;
}

@media (max-width: 1024px) {
  .links .link a {
    width: 100%;
  }
}

small {
  font-size: 70%;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #FF595A;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #00FFBB;
  display: block;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Browser Hacks
--------------------------------------------------------------*/
